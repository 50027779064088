import React from "react";
import styled from "styled-components";
import { useMediaQuery } from "react-responsive";

const JumpButtonSection = ({ orText, guideText, buttonText, url }) => {
  // const [roomData, roomDispatch] = useRoom();
  const isTablet = useMediaQuery({
    query: "(max-width: 1024px)"
  });

  return (
    <>
        <div style={{display:"flex", 
            flexDirection:"row", justifyContent:"center"}}>
          <div style={{display:"flex", 
            flexDirection:"row", 
            alignItems:"center" ,
            gap: isTablet ? "42px":"12px",
            marginTop:"24px",
            marginBottom:"24px",
            fontSize:"18px",
            lineHeight:"28px",
            color:"#999999"}}>
              <JumpDivider></JumpDivider>
              {orText}
              <JumpDivider></JumpDivider>
          </div>
        </div>
        <JumpContainer id="jumpc">
            <JumpGuide id="jumpguide">
            {guideText}
            </JumpGuide>
        </JumpContainer>
        
        <div style={{display:"flex", 
            flexDirection:"row", justifyContent:"center"}}>
        <a href={url}><JumpButton>{buttonText}</JumpButton></a>
        </div>
    </>
  );
};

const JumpContainer = styled.div`
  margin-bottom: 40px;
`;

const JumpDivider= styled.div`
  display:flex;
  flex-direction: column;
  height: 1px;
  width: 200px;
  background-color: #999999;
  
`;

const JumpGuide = styled.div`
  font-size: 20px;
  line-height: 30px;
  font-weight: 400;
  text-decoration: none;
  white-space: pre-line;
  color: #001426;
  text-align: center;
  ol {
    margin-top: 0px;
    margin-bottom: 0px;
    padding-left:20px;
  }

  @media only screen and (max-width: 1024px) {
  text-align: left;
  }
`;


const JumpButton = styled.button`
  background-color: #ffffff;
  border-width: 1px;
  border-color: #018098;
  border-radius: 8px;
  color: #018098;
  padding: 12px 96px;
  text-align: center;
  text-decoration: none;
  font-size: 16px;
  line-height: 24px;
  font-weight: 500;
  cursor: pointer;
  @media only screen and (max-width: 1024px) {
    padding: 8px 25px;
  }
`;

export default JumpButtonSection;
