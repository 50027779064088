import React from "react";
import LocalizedImage from "./LocalizedImage";
import styled from "styled-components";
import { useMediaQuery } from "react-responsive";
import {FIX_LOGO_SIZE, FIX_LOGO_SIZE_H, FIX_LOGO_SIZE_W} from "../utils/variables";
import WarningSection from "./WarningSection";
import { useText } from "./LocalizedText";
import { useContent } from "../contexts/contentContext";

const Header = ({ headline, subtitle, themeColor ,warningText, extendAuctionHint, extendAuctionNum}) => {
  // const [roomData, roomDispatch] = useRoom();
  const isTablet = useMediaQuery({
    query: "(max-width: 1024px)"
  });

	const [{ curr_lang }] = useContent();


  const logoStyle = {marginBottom: isTablet ? "24px" : "40px", maxWidth:"100%"};
  if (FIX_LOGO_SIZE) {
    if(FIX_LOGO_SIZE === "h") {
      logoStyle.height = FIX_LOGO_SIZE_H
    }
    else if (FIX_LOGO_SIZE === "w") {
      logoStyle.width = FIX_LOGO_SIZE_W
    }
  }

  let renderSubtitle = false;
  if(extendAuctionNum && extendAuctionNum !== "") {
    renderSubtitle = true;
  }

  const getText = useText()

  let logoURL = isTablet ? "td-logo-m-en.svg" :"td-logo-en.svg"
  if(curr_lang === "zhHant"){
    logoURL = isTablet ? "td-logo-m-tc.svg" :"td-logo-tc.svg"
  }
  else if(curr_lang === "zhHans"){
    logoURL = isTablet ? "td-logo-m-sc.svg" :"td-logo-sc.svg"
  }

  return (
    <>
      <img src={logoURL} 
        alt="" 
        style={logoStyle}></img>
      <div style={{ display: "flex", flexDirection: "column" }}>
        <Headline id="headline" color={themeColor}>
          {headline}
        </Headline>
        { renderSubtitle && (
          <div>
            <Subtitle id="extendAuction">
              {getText("headlineAfterEvent")}
              <span style={{color:"#018098"}}>{extendAuctionNum}</span>
            </Subtitle>
            <Subtitle id="subtitle">
              {subtitle}
            </Subtitle>
          </div>
        )
        }
        <WarningSection warningText={warningText}></WarningSection>
      </div>
    </>
  );
};

const Headline = styled.div`
  font-size: 32px;
  line-height: 40px;
  font-weight: 700;
  text-align: center;
  margin-bottom: 40px;
  text-decoration: none;
  white-space: pre-line;
  color: #001426};

  @media only screen and (max-width: 1024px) {
    font-size: 24px;
    line-height: 36px;
    text-align: left;
  }
`;

const Subtitle = styled.div`
  font-size: 20px;
  line-height: 30px;
  font-weight: 700;
  text-align: center;
  margin-bottom: 40px;
  white-space: pre-line;
  @media only screen and (max-width: 1024px) {
    font-size: 20px;
    line-height: 30px;
    text-align: left;
  }
`;

export default Header;
