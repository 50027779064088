import React from "react";
import styled from "styled-components";
import { useMediaQuery } from "react-responsive";

const WarningSection = ({ warningText }) => {
  // const [roomData, roomDispatch] = useRoom();
  const isTablet = useMediaQuery({
    query: "(max-width: 1024px)"
  });

  return (
    <>
        <WarningContainer id="warning">
            <img
                style={{
                    width: "48px",
                    height: "48px",
                    marginRight: "12px",
                    objectFit: "contain",
                }}
                src={"warning.svg"}
                alt=""
                />
            <div style={{ display: "flex", flexDirection: "column" }}>
                <WarningText id="warningText">
                {warningText}
                </WarningText>
            </div>
        </WarningContainer>
    </>
  );
};

const WarningContainer = styled.div`
  background-color: #fff9ec;
  border-color: #feac5a;
  border-style: solid;
  border-width: 1px;
  border-radius: 8px;
  padding: 12px;
  display: flex;
  flex-direction: row;
  margin-bottom: 40px;
`;

const WarningText = styled.div`
  font-size: 16px;
  line-height: 24px;
  font-weight: 400;
  text-decoration: none;
  white-space: pre-line;
  color: #001426;
  ol {
    margin-top: 0px;
    margin-bottom: 0px;
    padding-left:20px;
  }

  @media only screen and (max-width: 1024px) {
  }
`;

export default WarningSection;
