
function get(key) {
    if (!navigator?.cookieEnabled || !window?.localStorage ) return null
    try {
        return window.localStorage.getItem(key)
    } catch (error) {
        return null
    }
}

function set(key, val) {
    if (!navigator?.cookieEnabled || !window?.localStorage ) return null
    try {
        return window.localStorage.setItem(key, val)
    } catch (error) {
        return null
    }
}

const localStorage = {
    get, set,
}

export default localStorage