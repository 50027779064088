import React from "react";
import styled from "styled-components";
import { useContent } from "../contexts/contentContext";

const Popup = ({ target }) => {
	const [contentData, ContentDispatch] = useContent();
	const curr_lang = contentData?.curr_lang;

	const title = window.config.n_contents?.popup_title?.[curr_lang];
	const buttonText = window.config.n_contents?.popup_button?.[curr_lang];
	return (
		<PopupContainer>
			<PopupBox id="queue_finish_popup_div">
				<img
					style={{
						width: "64px",
						height: "64px",
					}}
					src={"/finalline.png"}
				/>
				<div style={{ marginTop: "0.25rem" }}>{title}</div>
				<RedirectButton
					id="queue_finish_button"
					href={target}
					style={{
						backgroundColor: `${window.config.n_c}`,
					}}
				>
					{buttonText}
				</RedirectButton>
			</PopupBox>
		</PopupContainer>
	);
};

const PopupContainer = styled.div`
	position: fixed;
	top: 0;
	left: 0;
	right: 0;
	bottom: 0;
	display: flex;
	justify-content: center;
	align-items: center;
	background-color: #00000088;
`;

const PopupBox = styled.div`
	border-radius: 0.25rem;
	padding: 3rem;
	background-color: #ffffff;
	font-size: 1.25rem;
	font-weight: bold;
	text-align: center;
`;

const RedirectButton = styled.a`
	display: block;
	font-size: 1rem;
	color: #ffffff;
	padding: 0.5rem;
	border-radius: 0.25rem;
	margin-top: 1.25rem;
	text-align: center;
	text-decoration: none;
	cursor: pointer;
	// background-color: #fc8181;
`;

export default Popup;
