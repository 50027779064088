import React, { useContext, useReducer } from "react";
import { contentReducer } from "./reducers/contentReducer";

const ContentContext = React.createContext(null);
ContentContext.displayName = "ContentContext";

export const ContentContextProvider = (props) => {
  const [state, dispatch] = useReducer(contentReducer, {
    vMessage: "this is temp vMessage.",
    d_contents: null,
    curr_lang: null,
    lang_options: null
  });
  return <ContentContext.Provider value={[state, dispatch]} {...props} />;
};

export const useContent = () => {
  return useContext(ContentContext);
};
