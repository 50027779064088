import React, { useEffect, useState } from "react";
// context
import { useRoom } from "../contexts/roomContext";
import { useContent } from "../contexts/contentContext";
// Libs
import { checkReferrer, removeNoqParamFromURL, sleep, resolveRedirectURL, removeTokenFromURL, prepareBeforeWork, checkCookiesSetted } from "../utils/index";
import { NOQ_STATUS_API, endpoints, DEMO_MODE, DEFAULT_ROOM_ID, STOPPED_REDIRECT, ENABLE_RESTRICT_REFERRER, REFERRER_FAIL_REDIRECT_URL } from "../utils/variables";
import { getJwtSessionId } from "../utils/sessionIdUtils";
import localStorage from "../utils/localStorage";
import { add, format } from "date-fns";
import Cookies from "js-cookie";
import axios from "axios";
import _ from "lodash";

let { noq_t, noq_c, noq_v, noq_r, verbose, KEY, referrer_check_success, ...query } = prepareBeforeWork();

const useAPIClient = () => {
  const [roomData, roomDispatch] = useRoom();
  const [contentData, contentDispatch] = useContent();
  const [callBeep, setCallBeep] = useState(false);

  useEffect(() => {
    if (callBeep) {
      beep();
    }
    setCallBeep(false);
  }, [callBeep]);
  
 

  const getRoomStatus = async (initRoomState) => {
    if (initRoomState?.clientId) {
      try {
        const { data } = await axios.get(`${NOQ_STATUS_API}${initRoomState?.clientId}`);
        return data;
      } catch (error) {
        console.log("Setup incorrect [Err0008]");
        console.log(error.response);
        return null;
      }
    } else {
      console.log("Setup incorrect [Err0007]");
      return null;
    }
  };

  const getV1EndPoint = (initRoomState) => {
    let endpoint = endpoints[initRoomState?.clientId];
    if (DEMO_MODE && !endpoint) {
      endpoint = endpoints["demo"] + initRoomState?.clientId;
    }
    return endpoint;
  };

  const getEndPoint = (initRoomState) => {
    if (initRoomState?.roomStatus) {
      if (initRoomState?.roomStatus.backend) {
        // v2.0
        return `https://${initRoomState?.roomStatus.backend}/queue/${initRoomState?.clientId}`;
      } else {
        if (initRoomState?.roomStatus.state === "stopped") {
          // v2.0 & stopped
          console.log("Queue Stopped [Err0010]");
          return "stopped";
        } else {
          // v1.0
          return getV1EndPoint(initRoomState);
        }
      }
    } else {
      // Status API error
      return null;
    }
  };

  const recordRoomQAPIError = () => {
    if (roomData?.errorCount > 2) {
      // redirect back after two beep failed
      if (roomData?.errorURL) {
        log(`Redirect after beep fails twice`);
        window.location.href = roomData?.errorURL;
        return;
      }
    }
    roomDispatch({ type: "SET_DATA", data: { errorCount: roomData?.errorCount + 1 } });
  };

  const log = (newMsg) => {
    if (verbose) {
      let msg = `${newMsg}
      ${roomData?.vMessage}`;
      contentDispatch({ type: "SET_DATA", data: { vMessage: msg } });
    }
  };

  const beep = async () => {
    if (!referrer_check_success) {
      return;
    }

    let initRoomState = _.cloneDeep(roomData);
    let roomStatus = await getRoomStatus(initRoomState);
    initRoomState = { ...initRoomState, roomStatus };

    // await initLanguageSelector();

    let token = Cookies.get(KEY);
    let jwt_session_id = getJwtSessionId(token);

    log(`[Beep] clientid:${roomData?.clientId}, token:${token}`);

    try {
      let endpoint = getEndPoint(initRoomState);
      if (endpoint === "stopped") {
        if (STOPPED_REDIRECT && initRoomState?.roomStatus.targetUrl) {
          const target = resolveRedirectURL(null, noq_r, initRoomState?.roomStatus.targetUrl, []);
          window.location.href = target;
          return;
        } else {
          await sleep(62000);
          roomDispatch({ type: "SET_DATA", data: initRoomState });
          setCallBeep(true);
          return;
        }
      } else if (!endpoint) {
        recordRoomQAPIError();
        // status api error
        await sleep(10000);
        roomDispatch({ type: "SET_DATA", data: initRoomState });
        setCallBeep(true);
        return;
      }
      const { data } = await axios.post(endpoint, {
        action: "beep",
        client_id: initRoomState?.clientId,
        id: token,
        jwt_session_id: jwt_session_id,
        options: {
          no_content: true
        }
      });

      const newToken = data.id;
      let new_jwt_session_id = getJwtSessionId(newToken);

      if (jwt_session_id !== new_jwt_session_id) {
        log(`***Token updated, old:${token}, new:${newToken}`);
      }

      if (newToken) {
        Cookies.set(KEY, newToken, {
          secure: false,
          expires: 0.5
        });
        token = checkCookiesSetted(KEY)
      }

      const event_status = data.event_status;
      const event_start_time = data.event_start_time;

      const queue_paused = data.vb === "paused";
      const est_time = parseInt(data.vb || 0, 10);
      const queue_position = est_time === 0 ? 0 : parseInt(data.va || 0, 10) / est_time;
      const redirect = data.vd % 2 === 0; // default setting
      // const redirect = false; // default setting test
      const total_in_queue = parseInt(data.vc || 0, 10) / redirect;
      const next_refresh_min = parseFloat(data.ve || 1) * 60 * 1000;
      const heartbeat = data.heartbeat;
      const target_domains = data.target_domains;
      const target_url = data.target_url;
      const enqueued = data.enqueued;

      let display_enqueue_notice = enqueued;
      if (initRoomState?.first_beep_received == false) {
        // first beep, always do not display notice
        display_enqueue_notice = false;
      }

      log(`Data: ${JSON.stringify(data)}`);
      let result = {};
      if (initRoomState?.queue_position === queue_position && initRoomState?.queue_position !== null) {
        result = {
          queue_position,
          redirect,
          total_in_queue,
          next_refresh_min,
          lastUpdateTime: new Date(),
          nextRefreshTime: new Date().getTime() + next_refresh_min,
          queue_paused,
          event_status,
          event_start_time,
          heartbeat,
          errorCount: 0,
          target_domains,
          target_url,
          first_beep_received: true
        };
      } else {
        const targetTime = add(new Date(), {
          minutes: est_time
        });
        result = {
          targetTime,
          queue_position,
          redirect,
          est_time,
          total_in_queue,
          next_refresh_min,
          lastUpdateTime: new Date(),
          nextRefreshTime: new Date().getTime() + next_refresh_min,
          queue_paused,
          event_status,
          event_start_time,
          heartbeat,
          errorCount: 0,
          target_domains,
          target_url,
          first_beep_received: true,
          enqueue_notice: display_enqueue_notice
        };
      }

      initRoomState = { ...initRoomState, ...result };
      roomDispatch({ type: "SET_DATA", data: initRoomState });

      if (redirect) {
        const target = resolveRedirectURL(token, noq_r, target_url, target_domains);

        if (target) {
          if (verbose) {
            alert(`redirect URL = ${target}`);
          }
          window.location.href = target;
        } else {
          console.warn("Setup incorrect [Err0003]");

          log(`Missing target_url in data`);

          await sleep(10000);
          setCallBeep(true);
        }
      } else if (!data.va && data.event_status !== "before" && data.event_status !== "after" && data.event_status !== "pause_period") {
        log(`VA is null, beep`);
        setCallBeep(true);
      } else {
        await sleep(next_refresh_min);
        setCallBeep(true);
      }
    } catch (e) {
      recordRoomQAPIError();

      if (e.response && e.response.status === 401) {
        // for 401 error only
        log(`[Error 401], return to login page`);
        if (e.response.data && e.response.data.loginUrl) {
          alert("登入信息無效，返回登入頁面\rInvalid login session, return to login page");
          window.location.href = e.response.data.loginUrl;
        } else {
          await sleep(10000);
          setCallBeep(true);
        }
      } else {
        log(`[Error Unknown], beep after 10s`);
        // if server return error, beep again after 10s
        await sleep(10000);
        setCallBeep(true);
      }
    }
  };

  const initLanguageSelector = async () => {
    const room_setting = await axios.get(`${NOQ_STATUS_API}${roomData?.clientId}/contents`);
    const { languages, ...room_contents } = room_setting.data;
    function checkLangAvailable(lang) {
      return languages
        .map((lang) => lang.key)
        .indexOf(lang) !== -1
    }
    let currLang = contentData?.curr_lang
    if (query.lang && checkLangAvailable(query.lang)) {
      currLang = query.lang
      localStorage.set('lang', query.lang)
    } else if (checkLangAvailable(localStorage.get('lang'))) {
      currLang = localStorage.get('lang')
    } else if (checkLangAvailable(window.langCodeConverter(navigator.language))) {
      currLang = window.langCodeConverter(navigator.language)
    } else if (!currLang) {
      currLang = languages[0]?.key
    }
    
    contentDispatch({
      type: "SET_DATA",
      data: {
        curr_lang: currLang,
        d_contents: Object.assign({}, room_contents),
        lang_options: languages
      }
    });
  };

  const startHeartBeat = () => {
    if (roomData?.heartbeat) {
      axios({
        url: roomData?.heartbeat.endpoint,
        withCredentials: true,
        method: roomData?.heartbeat.method.toLowerCase()
      });
    }
  };

  return {
    initLanguageSelector,
    startHeartBeat,
    beep
  };
};

export default useAPIClient;
