import React from "react";
import styled from "styled-components";
import { useRoom } from "../contexts/roomContext";
import { BAR_COLOR } from "../utils/variables";

const ProgressBarSection = ({ renderProgressBar }) => {
  const [roomData, roomDispatch] = useRoom();

  // running progress
  let goal_percent = 0.05;
  if (roomData?.est_time) {
    goal_percent = 1.0 - roomData?.est_time / 60;
  }
  if(goal_percent <= 0.05) goal_percent = 0.05;
  if(goal_percent >= 0.97) goal_percent = 0.97

  return (
    <div id="progress_bar_div" style={{ display: `${renderProgressBar ? "flex" : "none"}`, justifyContent:"center" , marginBottom: "40px", alignItems: "center" }}>
      <ProgressBarBase id="progress_bar">
        <ProgressRunningBar bgcolor={BAR_COLOR} width={`${goal_percent * 100}%`} />
        <img src="runningman.gif" alt="" style={{position:"absolute", left:`${goal_percent * 100 - 1.5}%`, width:"32px", height:"32px"}}></img>
      </ProgressBarBase>
      <img src="gl-icon.png" alt="" style={{marginLeft:"10px"}}></img>
    </div>
  );
};

const ProgressBarBase = styled.div`
  max-width: 640px;
  position: relative;
  height: 32px;
  flex: 1 1;
  border-radius: 8px;
  background: #E8E9EB;
;
`;

const ProgressRunningBar = styled(ProgressBarBase)`
  position: absolute;
  top: 0;
  left: 0;
  max-width: 100%;
  background: #00a88d;
  width: ${(props) => props.width};
`;

export default ProgressBarSection;
