import React from "react";
import styled from "styled-components";
import Cookies from "js-cookie";
import LanguageSector from "./LanguageSector";
import Header from "./Header";
import ProgressBarSection from "./ProgressBarSection";
import QueueDetail from "./QueueDetail";
import LocalizedText from "./LocalizedText";
import LocalizedImage from "./LocalizedImage";
import JumpButtonSection from "./JumpButtonSection";
import { useRoom } from "../contexts/roomContext";
import { useContent } from "../contexts/contentContext";
import { resolveRedirectURL, prepareBeforeWork } from "../utils/index";
import { THEME_COLOR, DETECT_URL_TITLE, DETECT_URL_SUBTITLE, DISPLAY_TICKET_DIV, DISPLAY_QUEUE_NUMBER, DISPLAY_WAITING_TIME, DISPLAY_ENQUEUE_NOTICE, USE_FINAL_POPUP, PRE_EVENT_CONGIG, POST_EVENT_CONFIG, DEFAULT_ROOM_ID ,DISPLAY_TICKET_DIV_BEFORE_EVENT, JUMP_URLS} from "../utils/variables";

const WaitingRoom = ({ data }) => {
  const [roomData, roomDispatch] = useRoom();
  const [contentData, ContentDispatch] = useContent();

  console.log("roomData");
  console.log(roomData);
  console.log("contentData");
  console.log(contentData);

  let { noq_c, noq_r } = prepareBeforeWork();
  let KEY = window.config.n_token_key + `-${DEFAULT_ROOM_ID}` || `noq-room-token-${DEFAULT_ROOM_ID}`;
  let token = Cookies.get(KEY);
 
  // default render component logic
  let renderQueuePosition = true;
  let renderQueueEstTime = true;
  let renderProgressBar = true;
  let renderEnqueueNotice = !DISPLAY_ENQUEUE_NOTICE ? false : roomData?.enqueue_notice;
  let renderUpdateTime = true;
  let renderTicketNo = DISPLAY_TICKET_DIV;
  let renderVerbo = false;
  let renderRedirectPopup = USE_FINAL_POPUP ? USE_FINAL_POPUP : false;
  let target = resolveRedirectURL(token, noq_r, roomData?.target_url, roomData?.target_domains);

  // display custom or default text thread
  let showQueuePosition = !roomData?.queue_paused;
  let showQueueEstTime = !roomData?.queue_paused;

  let headline = <LocalizedText textKey="headline" />;
  let subtitle = <LocalizedText textKey="subtitle" />;
  // logic for different event status
  if (roomData?.event_status === "before") {
    console.log("event_status is before");
    if (roomData?.queue_position === 0) {
      // not yet enqueue
      showQueuePosition = false;
      showQueueEstTime = false;
      console.log('renderTicketNo')
      console.log(renderTicketNo)
      renderTicketNo = DISPLAY_TICKET_DIV_BEFORE_EVENT ? DISPLAY_TICKET_DIV_BEFORE_EVENT : false;
    } else {
      showQueuePosition = true;
      showQueueEstTime = false;
    }
    headline = <LocalizedText textKey="headlineBeforeEvent" />;
    subtitle = <LocalizedText textKey="subtitleBeforeEvent" />;
    renderProgressBar = PRE_EVENT_CONGIG.display_bar;
    renderQueuePosition = PRE_EVENT_CONGIG.display_qp;
    renderQueueEstTime = PRE_EVENT_CONGIG.display_ewt;
    renderUpdateTime = PRE_EVENT_CONGIG.display_upt;
  } else if (roomData?.event_status === "after") {
    console.log("event_status is after");
    showQueuePosition = false;
    showQueueEstTime = false;
    headline = <LocalizedText textKey="headlineAfterEvent" />;
    subtitle = <LocalizedText textKey="subtitleAfterEvent" />;
    renderProgressBar = POST_EVENT_CONFIG.display_bar;
    renderQueuePosition = POST_EVENT_CONFIG.display_qp;
    renderQueueEstTime = POST_EVENT_CONFIG.display_ewt;
    renderUpdateTime = POST_EVENT_CONFIG.display_upt;
  } else if (roomData?.event_status === "pause_period") {
    console.log("event_status is pp");
    showQueuePosition = false;
    showQueueEstTime = false;
    headline = <LocalizedText textKey="headlinePP" />;
    subtitle = <LocalizedText textKey="subtitlePP" />;
    renderTicketNo = false
    renderProgressBar = PRE_EVENT_CONGIG.display_bar;
    renderQueuePosition = PRE_EVENT_CONGIG.display_qp;
    renderQueueEstTime = PRE_EVENT_CONGIG.display_ewt;
    renderUpdateTime = PRE_EVENT_CONGIG.display_upt;
  }

  if (!DISPLAY_QUEUE_NUMBER) {
    renderQueuePosition = false;
  }

  // final control for waiting time div display
  if (!DISPLAY_WAITING_TIME) {
    renderQueueEstTime = DISPLAY_WAITING_TIME;
  }

  if (DETECT_URL_TITLE) {
    // if (true) {
    // for testing urify function
    headline = <LocalizedText textKey="headline" urlify="true" />;
  }
  if (DETECT_URL_SUBTITLE) {
    subtitle = <LocalizedText textKey="subtitle" urlify="true" />;
  }

  const warningText = <LocalizedText textKey="warningText" urlify="false" insertHtml="true" />;


  const orText = <LocalizedText textKey="orText" urlify="false" insertHtml="false" />;
  const guideText = <LocalizedText textKey="guideText" urlify="false" insertHtml="false" />;
  const buttonText = <LocalizedText textKey="jumpButtonText" urlify="false" insertHtml="false" />;
  const extendAuctionHint = <LocalizedText textKey="headlineAfterEvent" urlify="false" insertHtml="false" />;
	const extendAuctionNum = contentData.d_contents?.["subtitleAfterEvent"]?.[contentData.curr_lang] ? contentData.d_contents?.["subtitleAfterEvent"]?.[contentData.curr_lang] : window.config.n_contents?.["subtitleAfterEvent"]?.[contentData.curr_lang];
  const jumpButtonUrl = JUMP_URLS?.[contentData.curr_lang]

  // console.log("renderQueuePosition: ");
  // console.log(renderQueuePosition);
  // console.log("renderQueueEstTime: ");
  // console.log(renderQueueEstTime);

  const finalRenderState = {
    renderQueuePosition,
    renderQueueEstTime,
    renderEnqueueNotice,
    renderUpdateTime,
    renderTicketNo,
    renderVerbo,
    renderRedirectPopup,
    target,
    showQueuePosition,
    showQueueEstTime
  };

  return (
    <WaitingRoomContainer>
      <DetailSection>
        {/* <LanguageSector /> */}
        <Header headline={headline} subtitle={subtitle} themeColor={THEME_COLOR} warningText={warningText} extendAuctionHint={extendAuctionHint} extendAuctionNum={extendAuctionNum}/>
        <ProgressBarSection renderProgressBar={renderProgressBar} />
        <QueueDetail finalRenderState={finalRenderState} token={token} />
        <JumpButtonSection orText={orText} guideText={guideText} buttonText={buttonText} url={jumpButtonUrl}/>
      </DetailSection>
    </WaitingRoomContainer>
  );
};

const WaitingRoomContainer = styled.div`
  display: flex;
  min-height: 100vh;
  background-image: linear-gradient(#f5fcfc, #ffffff);
  align-items:center;
  justify-content: center;

  @media only screen and (max-width: 1024px) {
    align-items:flex-start;
  }
`;

const BannerSection = styled.div`
  width: 33.3%;
  border: 1px solid #e2e8f0;
  @media only screen and (max-width: 1024px) {
    display: none;
  }
`;

const DetailSection = styled.div`
  flex: 1;
  padding: 40px 100px;
  overflow-y: auto;
  background-color:white;
  margin: 40px 30px 30px 40px;
  border-radius: 32px;
  box-shadow: 0px 3px 6px #AAAAAA;
  max-width: 1160px;

  @media only screen and (max-width: 1024px) {
    padding: 12px;
    margin: 0px;
    border-radius: 0;
    box-shadow: none;
  }
`;

export default WaitingRoom;
