import jwt_decode from "jwt-decode";

export const decodeSessionId = (token) => {
  if (token) {
    try {
      let decoded = jwt_decode(token);
      decoded.n_error = false;
      return decoded;
    } catch (e) {
      return { session_id: "cannot decode token", n_error: true };
    }
  } else {
    return { session_id: "no token", n_error: true };
  }
};

export const getJwtSessionId = (token) => {
  let jwt_session_id = null;
  if (token) {
    let decoded = decodeSessionId(token);
    jwt_session_id = decoded.session_id;
  }
  return jwt_session_id;
};
