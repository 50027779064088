import React, { useEffect } from "react";
// components
import WaitingRoom from "./components/WaitingRoom";
import { useContent } from "./contexts/contentContext";
// contexts
import { useRoom } from "./contexts/roomContext";
// hooks
import useAPIClient from "./hooks/useAPIClient";
// Libs
import * as Sentry from "@sentry/react";
import {CONTENT_API_FREQUENCY} from './utils/variables'

if (window.config && window.config.n_sen) {
  Sentry.init({
    dsn: "https://9c6d794c35614a519a2d22cf526e262b@o201549.ingest.sentry.io/5394750"
  });
}

if (!String.prototype.startsWith) {
  Object.defineProperty(String.prototype, "startsWith", {
    value: function (search, rawPos) {
      const pos = rawPos > 0 ? rawPos | 0 : 0;
      return this.substring(pos, pos + search.length) === search;
    }
  });
}

const App = () => {
  const [roomData, roomDispatch] = useRoom();
  const [contentData, contentDispatch] = useContent();
  const { beep, initLanguageSelector, startHeartBeat } = useAPIClient();

  const c_api_frequency = CONTENT_API_FREQUENCY ? CONTENT_API_FREQUENCY : 60000;
  // content api
  useEffect(() => {
    const interval = setInterval(() => {
      initLanguageSelector();
    }, c_api_frequency);
    return () => clearInterval(interval);
  }, [contentData?.curr_lang]);

  useEffect(() => {
    initLanguageSelector();
    beep();
    return () => {};
  }, []);

  useEffect(() => {
    let heartBeatTimer = null;

    if (roomData?.heartbeat) {
      heartBeatTimer = setInterval(() => {
        startHeartBeat();
      }, roomData?.heartbeat.request_interval * 1000);
    }

    return () => (heartBeatTimer ? clearInterval(heartBeatTimer) : {});
  }, [roomData?.heartbeat]);

  return <WaitingRoom />;
};

export default App;
