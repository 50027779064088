import React, { useEffect, useRef, useState } from "react";
import styled from "styled-components";
import { useMediaQuery } from "react-responsive";
import { useContent } from "../contexts/contentContext";
import localStorage from "../utils/localStorage";

const LanguageSelector = (props) => {
	const isTablet = useMediaQuery({
		query: "(max-width: 1024px)",
	});

	// get supported lang option and current lang
	const [{ lang_options, curr_lang }, dispatch] = useContent();
	const [opened, setOpened] = useState(false);
	let displaySelectedLang = { key: curr_lang, name: window.config?.lang_key_pair?.[curr_lang] };

	const ref = useRef();

	useEffect(() => {
		const handler = (event) => {
			if (ref.current && !ref.current.contains(event.target)) {
				setOpened(false);
			}
		};
		document.addEventListener("mousedown", handler);
		return () => {
			document.removeEventListener("mousedown", handler);
		};
	}, []);

	const handleLanguageSelect = (lang) => {
		console.log("update lang~");
		setOpened(false);
		// setdisplaySelectedLang(lang);
		localStorage.set('lang', lang.key)
		dispatch({
			type: "SET_DATA",
			data: {
				curr_lang: lang.key,
			},
		});
	};

	// not render selector when lang option is empty
	if (!lang_options?.length > 0) {
		return null;
	}

	return (
		<LanguageSelectorContainer id="lang_selector_div" ref={ref}>
			<LanguageSelectorWrapper>
				<SelectedLangDisplayer id="lang_selector_trigger_div" onClick={() => setOpened((opened) => !opened)}>
					<img src={require("../assets/ic-language.svg").default} />
					<span id="lang_selector_span" style={{ minWidth: 90, margin: "0 0.75rem", display: isTablet ? "none" : "flex" }}>
						{displaySelectedLang.name}
					</span>
					<img src={require("../assets/ic-field-arrow-down.svg").default} style={{ marginLeft: "0.75rem" }} />
				</SelectedLangDisplayer>
				{opened && (
					<LanguageListDrawer id="lang_options_drawer" isTablet={isTablet}>
						{lang_options?.map((lang, index) => (
							<LangOptions id={"lang_option_" + index} key={lang.key} onClick={() => handleLanguageSelect(lang)} hoverBgColor={"#bee3f8"} hoverTextColor={"#2b6cb0"}>
								{lang.name}
							</LangOptions>
						))}
					</LanguageListDrawer>
				)}
			</LanguageSelectorWrapper>
		</LanguageSelectorContainer>
	);
};

const LanguageSelectorContainer = styled.div`
	display: flex;
	justify-content: flex-end;
	margin-left: auto;
	margin-bottom: 8px;
	position: relative;
`;

const LanguageSelectorWrapper = styled.div`
	position: relative;
`;

const SelectedLangDisplayer = styled.div`
	display: flex;
	flex-direction: row;
	align-items: center;
	height: 3rem;
	padding: 0 1rem;
	border-radius: 0.25rem;
	border: 1px solid #e2e8f0;
	box-shadow: 0 2px 8px 0 rgba(0, 0, 0, 0.12);
	background-color: #fff;
	cursor: pointer;
`;

const LanguageListDrawer = styled.div`
	position: absolute;
	top: 52px;
	left: ${(props) => props.isTablet?  'auto': '0px'};
	right: 0px;
	max-height: 144px;
	border-radius: 4px;
	box-shadow: 0 2px 8px 0 rgba(0, 0, 0, 0.12);
	background-color: #ffffff;
	min-width: 190px;
	z-index: 1;
	overflow-y: scroll;
`;
const LangOptions = styled.div`
	display: flex;
	height: 3rem;
	padding: 0 3rem;
	align-items: center;
	white-space: nowrap;
	cursor: pointer;
	&:hover {
		background-color: ${(props) => (props.hoverBgColor ? props.hoverBgColor : "rgba(211, 18, 68, 0.05)")};
		color: ${(props) => (props.hoverTextColor ? props.hoverTextColor : "#d31244")};
	}
`;

export default LanguageSelector;
