import React, { useContext, useReducer } from "react";
import { roomReducer } from "./reducers/roomReducer";
// Libs
import { removeNoqParamFromURL, prepareBeforeWork } from "../utils/index";
import { DEFAULT_ROOM_ID, errorBackupDestinationUrl } from "../utils/variables";

let { noq_c, noq_r } = prepareBeforeWork();

const RoomContext = React.createContext(null);
RoomContext.displayName = "RoomContext";

export const RoomContextProvider = (props) => {
  const [state, dispatch] = useReducer(roomReducer, {
    clientId: noq_c || DEFAULT_ROOM_ID,
    total_in_queue: 0,
    redirect: false,
    queue_position: null,
    est_time: null,
    targetTime: null,
    lastUpdateTime: null,
    nextRefreshTime: null,
    next_refresh_min: 0,
    queue_paused: false,
    event_status: "in",
    event_start_time: null,
    heartbeat: null,
    errorCount: 0,
    errorURL: errorBackupDestinationUrl || noq_r,
    first_beep_received: false,
    enqueue_notice: false,
    roomStatus: null,
    target_url: null,
    target_domains: null
  });
  return <RoomContext.Provider value={[state, dispatch]} {...props} />;
};

export const useRoom = () => {
  return useContext(RoomContext);
};
